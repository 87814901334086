<!--
 * @Author: xiaobaobeibai 604070135@qq.com
 * @Date: 2021-06-15 11:09:50
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-09-18 14:26:58
 * @FilePath: \sipingnongzhuan2\src\views\home\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index_box">
    <!-- banner pc-->
    <div
      v-if="isPhone === 'false'">
      <!-- <headerVue></headerVue> -->
      <div
        class="banner_box_index">
        <el-carousel
          height="350px"
          trigger="click">
          <el-carousel-item
            v-for="(item,index) in dblbList"
            :key="index">
            <div
              :style="{ backgroundImage: 'url(' + item.url + ')',backgroundRepeat:'no-repeat',backgroundSize:'cover',width:'100%',height:'350px' }">
              <div
                class="banner_text"
                style="height:350px;display:flex;justify-content:center;align-item:center"
                v-html="item.content">
              </div>
            </div>
            <!-- <img
              :src="item.url"
              style="width:100%;height:350px;"> -->
          </el-carousel-item>
          <!-- <el-carousel-item>
            <img
              src="../../assets/imgaes/banner_04.png"
              style="width:100%;height:350px">
          </el-carousel-item> -->
        </el-carousel>
      </div>
    </div>
    <!-- banner phone-->
    <div
      v-if="isPhone === 'true'">
      <!-- <headerVue></headerVue> -->
      <div
        class="banner_box_index">
        <el-carousel
          :height="phoneWidth + 'px'"
          trigger="click">
          <el-carousel-item
            v-for="(item,index) in dblbList"
            :key="index">
            <div
              :style="{ backgroundImage: 'url(' + item.url + ')',backgroundRepeat:'no-repeat',backgroundSize:'cover',width:'100%',height:phoneWidth + 'px' }">
              <div
                class="banner_text banner_text_phone"
                v-html="item.content">
              </div>
            </div>
            <!-- <img
              :src="item.url"
              style="width:100%;height:350px;"> -->
          </el-carousel-item>
          <!-- <el-carousel-item>
            <img
              src="../../assets/imgaes/banner_03.png"
              :style="{width:'100%',height:phoneWidth + 'px'}">
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../assets/imgaes/banner_04.png"
              :style="{width:'100%',height:phoneWidth + 'px'}">
          </el-carousel-item> -->
        </el-carousel>
      </div>
    </div>
    <!-- banner pc小图标-->
    <div>
      <div
        class="banner_box_index"
        :class="[isPhone == 'true'?'yewuPhone':'']">
        <div
          class="bg-white bg_img">
          <div
            class="yewu_box">
            <div
              style="overflow-x: auto;">
              <div
                class="yewu_box_item_box">
                <div
                  class="yewu_box_item"
                  v-for="(item,index) in projectLists"
                  :key="index">
                  <div
                    class="yewu"
                    :style="item.style">
                    <div
                      class="card_a">
                      <img
                        :src="item.url"
                        class="card_img">
                      <div
                        class="yewu_title">
                        {{item.name}}
                      </div>
                    </div>
                    <div
                      class="yewu_content">
                      <span
                        @click="gotoYewu(item)"
                        class="yewu_btn">查看详情</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="sy_tubiao">
          <div
            class="tubiao_center">
            <div
              class="tubiao_text">
              <div
                class="tubiao_i">
                <i
                  class="el-icon-document"></i>
              </div>
              <p>学校章程</p>
            </div>
            <div
              class="tubiao_text">
              <div
                class="tubiao_i">
                <i
                  class="el-icon-magic-stick"></i>
              </div>
              <p>专业设置</p>
            </div>
            <div
              class="tubiao_text">
              <div
                class="tubiao_i">
                <i
                  class="el-icon-reading"></i>
              </div>
              <p>规章制度</p>
            </div>
            <div
              class="tubiao_text">
              <div
                class="tubiao_i">
                <i
                  class="el-icon-chat-line-square"></i>
              </div>
              <p>招生资讯</p>
            </div>
            <div
              class="tubiao_text">
              <div
                class="tubiao_i">
                <i
                  class="el-icon-school"></i>
              </div>
              <p>政策法规</p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- banner pc小图标下面的-->
    <!-- <div
      v-if="isPhone === 'false'">
      <div
        class="banner_xinwen">
        <h3>校园动态和招考政策</h3>
        <div
          class="xinwen_icon">
        </div>
        <el-tabs
          class="xinwen_tabs"
          v-model="activeName"
          @tab-click="handleClick">
          <el-tab-pane
            label="招考信息"
            name="a">招考信息</el-tab-pane>
          <el-tab-pane
            label="高等特殊教育"
            name="b">高等特殊教育</el-tab-pane>
        </el-tabs>
      </div>
    </div> -->
    <!-- banner pc-->
    <div class="bg-img"
      style="padding:1px 0 0;position: relative;">
      <!-- <div class="bg-img"
      style="padding:20px 0 0;background: #fff"> -->
      <!-- 系部导航 -->
      <!-- <el-collapse-transition> -->
      <div
        v-if="isPhone =='false'"
        class="bg_menu">
        <div
          class="menu_title">
          <i style="font-size:20px"
            class="el-icon-mouse"></i>
          系部导航
        </div>
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          :collapse="true">
          <el-submenu
            v-for="(item,index) in xiList"
            :key="index"
            :index="index+''">
            <template
              slot="title">
              <img
                style="width:18px;margin-right:3px"
                :src="item.img"
                alt="">
              <!-- <i style="text-align:right;display:inline"
                class="el-icon-arrow-right"></i> -->
              <span
                style="display: inline-block;width:90px;height:56px;font-size:16px;font-weight:500"
                slot="title">{{item.name}}</span>
              <i style="text-align:right;display:inline"
                class="el-icon-arrow-right"></i>
            </template>
            <div
              v-for="(item2,index2) in item.son"
              :key="index2">
              <el-menu-item-group
                v-if="item2.label != '专业设置'"
                style="margin-top:-7px">
                <el-menu-item
                  @click="goXi(item,item2)"
                  :index="index +'-' + index2">{{item2.label}}</el-menu-item>
              </el-menu-item-group>
              <el-submenu
                v-if="item2.child && item2.child.length >0"
                :index="index +'-' + index2">
                <span
                  slot="title">专业设置</span>
                <el-menu-item
                  v-for="(item3,index3) in item2.child"
                  :key="index3"
                  @click="goXi(item,item2,item3)"
                  :index="index +'-' + index2 +'-' +index3">{{item3.xi}}</el-menu-item>
              </el-submenu>
            </div>
          </el-submenu>
        </el-menu>
      </div>
      <!-- 系部导航手机端 -->
      <div class="phoneNavR"
        @click="phoneNav"
        v-if="isPhone =='true'">
        <i
          class="el-icon-s-grid"></i>
      </div>
      <!-- 系部导航手机侧拉开始 -->
      <el-drawer title="系部导航"
        size="50%"
        :show-close="false"
        :visible.sync="phoneNavC">
        <el-menu
          :unique-opened='true'
          class="el-menu-vertical-demo">
          <el-submenu
            v-for="(item,index) in xiList"
            :index="index + ''"
            :key="index">
            <template
              slot="title">
              <img
                style="width:18px;margin-right:3px"
                :src="item.img"
                alt="">
              <span>{{item.name}}</span>
            </template>
            <div
              v-for="(item2,indexC) in item.son"
              :key="indexC">
              <el-submenu
                v-if="item2.label == '专业设置'"
                :index="index +'-'+indexC + ''">
                <template
                  slot="title">{{item2.label}}</template>
                <el-menu-item
                  @click="goXi(item,item2,item3)"
                  v-for="(item3,indexD) in item2.child"
                  :key="indexD"
                  :index="indexC +'-'+indexD + ''">{{item3.xi}}</el-menu-item>
              </el-submenu>
              <el-menu-item-group
                v-else>
                <el-menu-item
                  @click="goXi(item,item2)"
                  :index="index +'-'+indexC + ''">{{item2.label}}</el-menu-item>
              </el-menu-item-group>
            </div>
          </el-submenu>
        </el-menu>
      </el-drawer>
      <!-- 系部导航手机侧拉结束 -->
      <img class="bg_zi"
        src="../../assets/imgaes/bg_zi.png"
        alt="">
      <el-card class="card"
        shadow="never"
        style="margin:20px auto">
        <el-row :gutter="20">
          <el-col :lg="8"
            :md="12" :sm="24">
            <el-carousel
              style="margin-top:10px"
              height="235px"
              v-loading="loadingImg"
              indicator-position="none"
              arrow="hover"
              direction="horizontal">
              <!-- <el-carousel-item
                v-for="item in newsListImg"
                :key="item.id">
                <img
                  :src="item.imgUrl"
                  style="width:100%;height:100%">
                <div
                  class="news_footer">
                  {{item.title}}
                </div>
                <div
                  class="lunbo"
                  @click="gotoNews(item)">
                </div>
              </el-carousel-item> -->
              <el-carousel-item
                v-for="(item,index) in xilbList"
                :key="index">
                <img
                  :src="item"
                  style="width:100%;height:100%">
                <div
                  class="lunbo"
                  @click="gotoNews(item)">
                </div>
              </el-carousel-item>
            </el-carousel>
            <!-- <img
              src="https://www.cdu.edu.cn/attachment/a4d/1/202007/20200727223000n911vECK7f.jpg"
              style="width:100%;height:100%;margin-top:10px"> -->
          </el-col>
          <el-col :lg="8"
            :md="12" :sm="24">
            <div
              class="card_header">
              <div
                class="card_title">
                校园新闻
              </div>
              <div
                class="card_more">
                <span
                  @click="gotoNewListss(1)">更多>></span>
              </div>
            </div>
            <div
              class="nav_body"
              v-loading="loadingF">
              <div
                v-if="newsListF.length === 0"
                style="line-height:200px;text-align:center">
                暂无数据
              </div>
              <div v-else
                class="nav_item"
                @click="gotoOtherPage(item)"
                v-for="(item,index) in newsListF"
                :key="index">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Top Left 提示文字"
                  placement="top-start">
                  <div
                    slot="content">
                    {{item.title}}
                  </div>
                  <div
                    class="nav_item_left">
                    <div
                      class="icon">
                      <i class="el-icon-s-opportunity"
                        style="color:rgb(14, 73, 141)"></i>
                    </div>
                    <div
                      class="news_title">
                      {{item.title}}
                    </div>
                  </div>
                </el-tooltip>
                <div
                  class="nav_item_right">
                  <span>{{item.time}}</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :lg="8"
            :md="12" :sm="24">
            <div
              class="card_header">
              <div
                class="card_title">
                通知公告
              </div>
              <div
                class="card_more">
                <span
                  @click="gotoNewListss(4)">更多>></span>
              </div>
            </div>
            <div
              class="nav_body"
              v-loading="loadingF">
              <div
                class="nav_item"
                @click="gotoOtherPage2(item)"
                v-for="(item,index) in newsListG"
                :key="index">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Top Left 提示文字"
                  placement="top-start">
                  <div
                    slot="content">
                    {{item.title}}
                  </div>
                  <div
                    class="nav_item_left">
                    <div
                      class="icon">
                      <i class="el-icon-s-opportunity"
                        style="color:rgb(14, 73, 141)"></i>
                    </div>
                    <div
                      class="news_title">
                      {{item.title}}
                    </div>
                  </div>
                </el-tooltip>
                <div
                  class="nav_item_right">
                  <span>{{item.time}}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="card"
        shadow="never"
        style="margin:20px auto">
        <el-row :gutter="20">
          <el-col :lg="8"
            :md="12" :sm="24"
            v-if="isPhone === 'false'">
            <div
              class="card_header">
              <div
                class="card_title">
                常用链接
              </div>
              <!-- <div
                class="card_more">
                <span
                  @click="gotoNewListss(3)">更多>></span>
              </div> -->
            </div>
            <div
              class="card_body"
              style="margin-top:60px;display:flex">
              <div
                @click="goNet(1)"
                style="background: #00bac7"
                class="card_body_div">
                <img
                  class="card_body_img"
                  src="../../assets/imgaes/index_icon1.png"
                  alt="">
                <div
                  class="card_body_p">
                  学信网
                </div>
              </div>
              <div
                @click="goNet(2)"
                style="background: rgb(254, 192, 47)"
                class="card_body_div">
                <img
                  class="card_body_img"
                  src="../../assets/imgaes/index_icon4.png"
                  alt="">
                <div
                  class="card_body_p">
                  吉林省教育考试院
                </div>
              </div>
              <div
                @click="goNet(3)"
                style="background: #0072FF"
                class="card_body_div">
                <img
                  class="card_body_img"
                  src="../../assets/imgaes/logo.png"
                  alt="">
                <div
                  class="card_body_p">
                  亿学宝云
                </div>
              </div>
            </div>
          </el-col>
          <el-col :lg="8"
            :md="12" :sm="24"
            v-if="isPhone === 'true'">
            <div
              class="card_header">
              <div
                class="card_title">
                常用链接
              </div>
              <!-- <div
                class="card_more">
                <span
                  @click="gotoNewListss(3)">更多>></span>
              </div> -->
            </div>
            <div
              class="card_body"
              style="margin-top:30px;margin-bottom:50px;display:flex">
              <div
                @click="goNet(1)"
                style="background: #00bac7"
                class="card_body_div">
                <img
                  class="card_body_img"
                  src="../../assets/imgaes/index_icon1.png"
                  alt="">
                <div
                  class="card_body_p">
                  学信网
                </div>
              </div>
              <div
                @click="goNet(2)"
                style="background: rgb(254, 192, 47)"
                class="card_body_div">
                <img
                  class="card_body_img"
                  src="../../assets/imgaes/index_icon4.png"
                  alt="">
                <div
                  class="card_body_p">
                  吉林省教育考试院
                </div>
              </div>
              <div
                @click="goNet(3)"
                style="background: #0072FF"
                class="card_body_div">
                <img
                  class="card_body_img"
                  src="../../assets/imgaes/logo.png"
                  alt="">
                <div
                  class="card_body_p">
                  亿学宝云
                </div>
              </div>
            </div>
          </el-col>
          <el-col :lg="8"
            :md="12" :sm="24">
            <div
              class="card_header">
              <div
                class="card_title">
                党团活动
              </div>
              <div
                class="card_more">
                <span
                  @click="gotoNewListss(2)">更多>></span>
              </div>
            </div>
            <div
              class="nav_body"
              v-loading="loadingF">
              <div
                v-if="newsListF.length === 0"
                style="line-height:200px;text-align:center">
                暂无数据
              </div>
              <div v-else
                class="nav_item"
                @click="gotoOtherPage3(item)"
                v-for="(item,index) in newsListF2"
                :key="index">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Top Left 提示文字"
                  placement="top-start">
                  <div
                    slot="content">
                    {{item.title}}
                  </div>
                  <div
                    class="nav_item_left">
                    <div
                      class="icon">
                      <i class="el-icon-s-opportunity"
                        style="color:rgb(14, 73, 141)"></i>
                    </div>
                    <div
                      class="news_title">
                      {{item.title}}
                    </div>
                  </div>
                </el-tooltip>
                <div
                  class="nav_item_right">
                  <span>{{item.time}}</span>
                </div>
              </div>

            </div>
          </el-col>
          <el-col :lg="8"
            :md="12" :sm="24">
            <div
              class="card_header">
              <div
                class="card_title">
                教学动态
              </div>
              <div
                class="card_more">
                <span
                  @click="gotoNewListss(3)">更多>></span>
              </div>
            </div>
            <div
              class="nav_body"
              v-loading="loadingF">
              <div
                class="nav_item"
                @click="gotoOtherPage4(item)"
                v-for="(item,index) in newsListG2"
                :key="index">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Top Left 提示文字"
                  placement="top-start">
                  <div
                    slot="content">
                    {{item.title}}
                  </div>
                  <div
                    class="nav_item_left">
                    <div
                      class="icon">
                      <i class="el-icon-s-opportunity"
                        style="color:rgb(14, 73, 141)"></i>
                    </div>
                    <div
                      class="news_title">
                      {{item.title}}
                    </div>
                  </div>
                </el-tooltip>
                <div
                  class="nav_item_right">
                  <span>{{item.time}}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <div class="blue_box">
        <div
          class="blue_1200">
          <div
            class="card_header">
            <div
              class="card_title">
              校园文化
            </div>
            <!-- <div
              class="card_more">
              <span
                @click="gotoProject(4,0)">更多>></span>
            </div> -->
          </div>
          <div
            class="project_boxs"
            v-loading="loadingC">
            <vue-seamless-scroll
              style="height:210px"
              :data="dilbList"
              :class-option="classOption"
              class="warp">
              <ul
                class="ul-item">
                <li
                  class="li-item"
                  v-for="(item, index) in dilbList"
                  :key="index">
                  <img
                    style="width:367px;height:200px;border:5px solid #fff"
                    :src="item"
                    alt="">
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
          <!-- <div
            class="project_boxs"
            v-loading="loadingC"
            v-if="isPhone ==='true'">
            <el-carousel
              height="220px"
              indicator-position="outside"
              v-loading="loadingC"
              arrow="always">
              <el-carousel-item
                v-for="item in projectList"
                :key="item.id">
                <div
                  class="item_boxes">
                  <img
                    :src="item.imgUrl">
                  <div
                    class="item_footer">
                    {{item.name}}
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <Footer></Footer>
    <BackTop></BackTop> -->
  </div>

</template>

<script>
// import headerVue from '../../components/header_index.vue'
// import Footer from '../../components/footer'
// import BackTop from '../../components/back_top'
import axios from 'axios'
import ProjectIcon1 from '../../assets/imgaes/index_icon1.png'
import ProjectIcon2 from '../../assets/imgaes/index_icon2.png'
import ProjectIcon3 from '../../assets/imgaes/index_icon3.png'
import ProjectIcon4 from '../../assets/imgaes/index_icon4.png'
import ProjectIcon5 from '../../assets/imgaes/index_icon5.png'
import xiaotubiao1 from '../../assets/imgaes/xiaotubiao1.png'
import xiaotubiao2 from '../../assets/imgaes/xiaotubiao2.png'
import xiaotubiao3 from '../../assets/imgaes/xiaotubiao3.png'
import xiaotubiao4 from '../../assets/imgaes/xiaotubiao4.png'
import xiaotubiao5 from '../../assets/imgaes/xiaotubiao5.png'
import xiaotubiao6 from '../../assets/imgaes/xiaotubiao6.png'
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  components: {
    vueSeamlessScroll
  },
  // components: { headerVue, Footer, BackTop },
  data() {
    return {
      listData: [1, 2, 3, 4, 5, 6],
      classOption: {
        limitMoveNum: 4,
        direction: 2
      },
      scrollTop: 0,
      phoneWidth: 0,
      phoneNavC: false,
      showMenu: true,
      loadingImg: false,
      loadingA: false,
      loadingB: false,
      loadingC: false,
      loadingVideo: false,
      loadingF: false,
      loadingaaa: false,
      loadingbbb: false,
      newsUrl: '',
      isPhone: 'false',
      isActive: 0,
      projectWidth: '',
      dblbList: [],
      xilbList: [],
      dilbList: [],
      newsListImg: [
        {
          id: '1',
          imgUrl: 'https://www.cdu.edu.cn/attachment/a4d/1/202007/20200727223000n911vECK7f.jpg',
          title: '教学楼'
        },
        {
          id: '2',
          imgUrl: 'https://img1.baidu.com/it/u=1950791653,2490835845&fm=253&fmt=auto&app=120&f=JPEG?w=1160&h=800',
          title: '校内湖畔'
        },
        {
          id: '3',
          imgUrl: 'https://www.cqeec.com/2017/images/20171109094323pRkKmRiG.jpg',
          title: '操场落日'
        }
      ],
      newsList: [
        {
          title: '四平农专XX学科建立10周年庆祝会举行',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '教育学院深入开展习近平文化思想专题学习',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '回首来时路，悠悠五大洲',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '汇丰教授参加2023丹东三校研讨会',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '探讨商业生态系统面临的挑战',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '示范学生宿舍评选活动即将举行',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '惠及民生的合作项目连续开展',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '“一带一路”上的人民故事',
          time: '2023-10-20 10:35:22'
        }
      ],
      newsListF: [],
      newsListF2: [
        // {
        //   title: '“服务群众 奉献社会” 迎春社区义务清雪活动',
        //   time: '2023-11-07  10:00:00',
        //   type: 1
        // }
      ],
      newsListG: [
        {
          title: '关于做好我校2024届毕业生图像信息现场采集工作的通知公告',
          time: '2023-09-05 16:44:57',
          type: 1
        },
        {
          title: '关于更换我校网课平台的通知公告',
          time: '2023-09-04 12:33:23',
          type: 2
        }
      ],
      newsListG2: [],
      projectLists: [
        {
          id: 1,
          name: '学校章程',
          url: ProjectIcon1,
          style: 'background: rgb(29, 104, 189)',
          path: '/xxzc',
          ids: '7119320676409806848'
        },
        {
          id: 2,
          name: '专业设置',
          url: ProjectIcon2,
          style: 'background: rgb(105, 186, 110)',
          path: '/zysz',
          ids: '7121945416974733312'
        },
        {
          id: 3,
          name: '招生快讯',
          url: ProjectIcon3,
          style: 'background: rgb(52, 198, 217)',
          path: '/zszx',
          ids: '7119322921234862080'
        },
        {
          id: 4,
          name: '教师风采',
          url: ProjectIcon4,
          style: 'background: rgb(254, 192, 47)',
          path: '/jsfc',
          ids: '7121946740629966848'
        },
        {
          id: 5,
          name: '政策法规',
          url: ProjectIcon5,
          style: 'background: rgb(252, 122, 148)',
          path: '/zcfg',
          ids: '7119323130610323456'
        }
      ],
      // projectList: [
      //   {
      //     id: '6719967013143318528',
      //     createTime: '2020-10-26 10:35:22',
      //     projectId: '6708373195717611520',
      //     name: '团结协助互相学习',
      //     url: '20201026/1603679720712.jpg',
      //     isEnable: 0,
      //     sort: 34,
      //     content: '<p>团结协助互相学习</p>',
      //     imgUrl: require('../../assets/lunbo/1.jpg')
      //   },
      //   {
      //     id: '6719966865512206336',
      //     createTime: '2020-10-26 10:34:47',
      //     projectId: '6708373128956874752',
      //     name: '学校正能量激励板',
      //     url: '20201026/1603679685104.jpg',
      //     isEnable: 0,
      //     sort: 33,
      //     content: '<p>学校正能量激励板</p>',
      //     imgUrl: require('../../assets/lunbo/2.jpg')
      //   },
      //   {
      //     id: '6719966802056581120',
      //     createTime: '2020-10-26 10:34:32',
      //     projectId: '6708373128956874752',
      //     name: '诗书文化墙',
      //     url: '20201026/1603679669807.png',
      //     isEnable: 0,
      //     sort: 32,
      //     content: '<p>诗书文化墙</p>',
      //     imgUrl: require('../../assets/lunbo/3.jpg')
      //   },
      //   {
      //     id: '6719966802056581120',
      //     createTime: '2020-10-26 10:34:32',
      //     projectId: '6708373128956874752',
      //     name: '书法大赛',
      //     url: '20201026/1603679669807.png',
      //     isEnable: 0,
      //     sort: 32,
      //     content: '<p>书法大赛</p>',
      //     imgUrl: require('../../assets/lunbo/4.jpg')
      //   },
      //   {
      //     id: '6719966802056581120',
      //     createTime: '2020-10-26 10:34:32',
      //     projectId: '6708373128956874752',
      //     name: '武术运动文化节',
      //     url: '20201026/1603679669807.png',
      //     isEnable: 0,
      //     sort: 32,
      //     content: '<p>武术运动文化节</p>',
      //     imgUrl: require('../../assets/lunbo/5.jpg')
      //   },
      //   {
      //     id: '6719966802056581120',
      //     createTime: '2020-10-26 10:34:32',
      //     projectId: '6708373128956874752',
      //     name: '国庆节传统服饰校园文化活动',
      //     url: '20201026/1603679669807.png',
      //     isEnable: 0,
      //     sort: 32,
      //     content: '<p>国庆节传统服饰校园文化活动</p>',
      //     imgUrl: require('../../assets/lunbo/6.jpg')
      //   },
      //   {
      //     id: '6719966802056581120',
      //     createTime: '2020-10-26 10:34:32',
      //     projectId: '6708373128956874752',
      //     name: '书法大赛',
      //     url: '20201026/1603679669807.png',
      //     isEnable: 0,
      //     sort: 32,
      //     content: '<p>书法大赛</p>',
      //     imgUrl: require('../../assets/lunbo/7.jpg')
      //   },
      //   {
      //     id: '6719966802056581120',
      //     createTime: '2020-10-26 10:34:32',
      //     projectId: '6708373128956874752',
      //     name: '武术运动文化节',
      //     url: '20201026/1603679669807.png',
      //     isEnable: 0,
      //     sort: 32,
      //     content: '<p>武术运动文化节</p>',
      //     imgUrl: require('../../assets/lunbo/8.jpg')
      //   },
      //   {
      //     id: '6719966802056581120',
      //     createTime: '2020-10-26 10:34:32',
      //     projectId: '6708373128956874752',
      //     name: '国庆节传统服饰校园文化活动',
      //     url: '20201026/1603679669807.png',
      //     isEnable: 0,
      //     sort: 32,
      //     content: '<p>国庆节传统服饰校园文化活动</p>',
      //     imgUrl: require('../../assets/lunbo/9.jpg')
      //   },
      //   {
      //     id: '6719966802056581120',
      //     createTime: '2020-10-26 10:34:32',
      //     projectId: '6708373128956874752',
      //     name: '书法大赛',
      //     url: '20201026/1603679669807.png',
      //     isEnable: 0,
      //     sort: 32,
      //     content: '<p>书法大赛</p>',
      //     imgUrl: require('../../assets/lunbo/10.jpg')
      //   },
      //   {
      //     id: '6719966802056581120',
      //     createTime: '2020-10-26 10:34:32',
      //     projectId: '6708373128956874752',
      //     name: '武术运动文化节',
      //     url: '20201026/1603679669807.png',
      //     isEnable: 0,
      //     sort: 32,
      //     content: '<p>武术运动文化节</p>',
      //     imgUrl: require('../../assets/lunbo/11.jpg')
      //   },
      //   {
      //     id: '6719966802056581120',
      //     createTime: '2020-10-26 10:34:32',
      //     projectId: '6708373128956874752',
      //     name: '国庆节传统服饰校园文化活动',
      //     url: '20201026/1603679669807.png',
      //     isEnable: 0,
      //     sort: 32,
      //     content: '<p>国庆节传统服饰校园文化活动</p>',
      //     imgUrl: require('../../assets/lunbo/12.jpg')
      //   }
      // ],
      projectId: '',
      activeName: 'a',
      projectParentId: '6708371247165607936',
      news_navs: [{ name: '教学动态' }, { name: '通知公告' }],
      xiList: [
        {
          name: '农业工程系',
          img: xiaotubiao1,
          son: [
            {
              label: '专业设置',
              child: [
                { xi: '作物生产与经营管理', value: '1111' },
                { xi: '林业技术', value: '1112' },
                { xi: '园林技术', value: '1113' },
                { xi: '水利工程', value: '1114' }
              ]
            },
            { label: '系部简介', value: '1115' },
            { label: '机构设置', value: '1116' },
            { label: '师资力量', value: '1117' },
            { label: '人才培养方案', value: '1118' },
            { label: '教学管理', value: '1119' },
            { label: '教学教研', value: '1120' }
          ]
        },
        {
          name: '牧医系',
          img: xiaotubiao2,
          son: [
            {
              label: '专业设置',
              child: [
                { xi: '畜牧兽医', value: '2221' },
                { xi: '食品智能加工技术', value: '2222' }
              ]
            },
            { label: '系部简介', value: '2223' },
            { label: '机构设置', value: '2224' },
            { label: '师资力量', value: '2225' },
            { label: '人才培养方案', value: '2226' },
            { label: '教学管理', value: '2227' },
            { label: '教学教研', value: '2228' }
          ]
        },
        {
          name: '财经系',
          img: xiaotubiao3,
          son: [
            {
              label: '专业设置',
              child: [
                { xi: '大数据与会计', value: '3331' },
                { xi: '国际经济与贸易', value: '3332' },
                { xi: '工商企业管理', value: '3333' },
                { xi: '现代物流管理', value: '3334' },
                { xi: '国土资源调查与管理', value: '3335' }
              ]
            },
            { label: '系部简介', value: '3336' },
            { label: '机构设置', value: '3337' },
            { label: '师资力量', value: '3338' },
            { label: '人才培养方案', value: '3339' },
            { label: '教学管理', value: '3340' },
            { label: '教学教研', value: '3341' }
          ]
        },
        {
          name: '人文系',
          img: xiaotubiao4,
          son: [
            {
              label: '专业设置',
              child: [
                { xi: '法律事务', value: '4441' },
                { xi: '社会工作', value: '4442' },
                { xi: '戏曲表演', value: '4443' },
                { xi: '新闻采编与制作', value: '4444' }
              ]
            },
            { label: '系部简介', value: '4445' },
            { label: '机构设置', value: '4446' },
            { label: '师资力量', value: '4447' },
            { label: '人才培养方案', value: '4448' },
            { label: '教学管理', value: '4449' },
            { label: '教学教研', value: '4450' }
          ]
        },
        {
          name: '计算机系',
          img: xiaotubiao5,
          son: [
            {
              label: '专业设置',
              child: [
                { xi: '计算机应用技术', value: '5551' },
                { xi: '机电一体化技术', value: '5552' },
                { xi: '机械制造及自动化', value: '5553' },
                { xi: '供用电技术', value: '5554' },
                { xi: '城乡规划', value: '5555' }
              ]
            },
            { label: '系部简介', value: '5556' },
            { label: '机构设置', value: '5557' },
            { label: '师资力量', value: '5558' },
            { label: '人才培养方案', value: '5559' },
            { label: '教学管理', value: '5560' },
            { label: '教学教研', value: '5561' }
          ]
        },
        {
          name: '思政教研室',
          img: xiaotubiao6,
          son: [
            { label: '教研室简介', value: '6662' },
            { label: '课程设置', value: '6663' },
            { label: '师资力量', value: '6664' },
            { label: '教学管理', value: '6665' },
            { label: '教研活动', value: '6666' },
            { label: '理论前沿', value: '6667' },
            { label: '学习专栏', value: '6668' }
          ]
        }
      ]
    }
  },
  computed: {
    optionLeft() {
      return {
        step: 2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量(不能超过partnerList长度)
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  // computed: {
  //   optionLeft() {
  //     return {
  //       direction: 2,
  //       limitMoveNum: 2
  //     }
  //   }
  // },
  created() {
    this.getDTHD()
    this.getLb()
    console.log(window.screen.width)
    this.phoneWidth = window.screen.width / 5.5
  },
  mounted() {
    // window.addEventListener('scroll', this.getHtmlBodyHeight)
    // setTimeout(() => {
    // console.log(this.$store.state.navList[4].id, '000')
    // this.projectParentId = this.$store.state.navList[4].id
    console.log(typeof window.localStorage.getItem('isPhone'))
    if (window.localStorage.getItem('isPhone')) this.isPhone = window.localStorage.getItem('isPhone')
    else this.isPhone = 'false'
    // this.getNewsImg()
    // this.getNews()
    // this.getNewsVideo()
    // this.getNewsF()
    // this.getNewsG()
    // this.getProject()
    // this.getHtmlBodyHeight()
    // }, 1000)
  },
  methods: {
    // 手机端系部导航
    phoneNav() {
      this.phoneNavC = true
    },
    // 系部导航
    goXi(item1, item2, item3) {
      console.log(item1)
      console.log(item2)
      console.log(item3)
      this.$router.push({
        path: '/xi',
        query: { name1: item1.name, name2: item2.label, name3: item3 ? item3.xi : '', id: item3 ? item3.value : item2.value }
      })
    },
    getDTHD() {
      // 获取首页党团活动数据
      const obj = {
        pageNum: 1,
        pageSize: 5,
        isEnable: 0,
        projectId: '7119321341903245312'
      }
      this.$post('/detailsContent/selectListByProjectId', obj).then(res => {
        console.log(res)
        this.newsListF2 = res.data.list
      })
      // 获取首页校园新闻数据
      const obj1 = {
        pageNum: 1,
        pageSize: 5,
        isEnable: 0,
        projectId: '7121953223300091904'
      }
      this.$post('/detailsContent/selectListByProjectId', obj1).then(res => {
        console.log(res)
        this.newsListF = res.data.list
      })
      // 获取首页教学动态数据
      const obj2 = {
        pageNum: 1,
        pageSize: 5,
        isEnable: 0,
        projectId: '7121945916050771968'
      }
      this.$post('/detailsContent/selectListByProjectId', obj2).then(res => {
        console.log(res)
        this.newsListG2 = res.data.list
      })
      // 获取首页通知公告数据
      const obj3 = {
        pageNum: 1,
        pageSize: 5,
        isEnable: 0,
        projectId: '7121953621465370624'
      }
      this.$post('/detailsContent/selectListByProjectId', obj3).then(res => {
        console.log(res)
        this.newsListG = res.data.list
      })
    },
    // 获取首页轮播图
    getLb() {
      const obj = {
        pageNum: 1,
        pageSize: 999,
        isEnable: 0,
        projectId: '7235653758766551040'
      }
      this.$post('/detailsContent/selectListByProjectId', obj).then(res => {
        console.log(res)
        // this.dblbList = res.data.list.map(item => {
        //   return res.data.imgUrl + '/' + item.url
        // })
        let data = res.data.list
        for (let i = 0; i < data.length; i++) {
          data[i].url = res.data.imgUrl + '/' + data[i].url
        }
        this.dblbList = data
        console.log(this.dblbList)
      })
      const obj1 = {
        pageNum: 1,
        pageSize: 999,
        isEnable: 0,
        projectId: '7134509688892166144'
      }
      this.$post('/detailsContent/selectListByProjectId', obj1).then(res => {
        console.log(res)
        this.xilbList = res.data.list.map(item => {
          return res.data.imgUrl + '/' + item.url
        })
      })
      const obj2 = {
        pageNum: 1,
        pageSize: 999,
        isEnable: 0,
        projectId: '7134509769670266880'
      }
      this.$post('/detailsContent/selectListByProjectId', obj2).then(res => {
        console.log(res)
        this.dilbList = res.data.list.map(item => {
          return res.data.imgUrl + '/' + item.url
        })
        console.log(this.dilbList)
      })
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    getProject() {
      // this.loadingC = true
      this.projectList = []
      axios
        .post('/home/engineering/case', {
          projectParentId: this.projectParentId,
          pageNum: 1,
          pageSize: 20
        })
        .then(res => {
          const resourcesDtoList = res.data.data.resourcesDtoList
          if (resourcesDtoList.length > 9) {
            resourcesDtoList.map(item => this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url))
            // this.projectList = resourcesDtoList.slice(0,9)
            if (this.isPhone !== 'true') {
              const arr0 = resourcesDtoList.slice(0, 3)
              const arr1 = resourcesDtoList.slice(3, 6)
              const arr2 = resourcesDtoList.slice(6, 9)
              const arr3 = resourcesDtoList.slice(9, 12)
              const arr4 = resourcesDtoList.slice(12, 15)
              this.projectList.push(arr0, arr1, arr2, arr3, arr4)
            } else {
              this.projectList = resourcesDtoList.slice(0, 6)
            }
            console.log(this.projectList)
          } else {
            resourcesDtoList.map(item => this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url))
            this.projectList = resourcesDtoList
            console.log(this.projectList)
          }
          this.loadingC = false
        })
    },
    gotoProject(i, j) {
      this.$store.commit('setOpenNav', [i, j])
      this.$router.push({
        path: '/project_way_index'
      })
    },
    getNewsImg() {
      // this.loadingImg = true
      // axios.post('/home/newNews', { pageNum: 1, pageSize: 3, type: 2 }).then(res => {
      //   this.newsListImg = res.data.data.newsList
      //   console.log(this.newsListImg)
      //   this.newsListImg.map(item => {
      //     this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url)
      //   })
      //   console.log(this.newsListImg)
      //   this.loadingImg = false
      // })
    },
    getNews() {
      // this.loadingB = true
      axios.post('/home/newNews', { pageNum: 1, pageSize: 8, type: 2 }).then(res => {
        this.newsList = res.data.data.newsList
        console.log(this.newsList)
        this.newsList.map(item => {
          this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url)
        })
        this.loadingB = false
      })
    },
    getNewsA() {
      // this.loadingaaa = true
      axios.post('/home/newNews', { pageNum: 1, pageSize: 8, type: 3 }).then(res => {
        this.newsList = res.data.data.newsList
        this.newsList.map(item => {
          this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url)
        })
        this.loadingaaa = false
      })
    },
    getNewsB() {
      // this.loadingB = true
      axios
        .post('/home/engineering/case', {
          pageNum: 1,
          projectId: '6718156750056329216',
          pageSize: 8
        })
        .then(res => {
          this.newsList = res.data.data.resourcesDtoList
          this.newsList.map(item => {
            this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url)
            this.$set(item, 'time', item.createTime)
            this.$set(item, 'title', item.name)
          })
          if (this.newsList.length > 8) {
            this.newsList.splice(8, this.newsList.length)
          }
          this.loadingB = false
        })
    },
    gotoNews(item) {
      console.log(item)
      // this.$store.commit('setOpenNav', [6, item.type - 1])
      // this.$router.push({
      //   path: '/news_details',
      //   query: {
      //     id: item.id
      //   }
      // })
    },
    gotoYewu(item) {
      this.$store.commit('PATH', item.path)
      this.$store.commit('ID', item.ids)
      this.$router.push({ path: item.path, query: { id: item.ids } })
    },
    gotoNewes(e, i) {
      if (i === 2) {
        this.$store.commit('setOpenNav', [7, 2])
        this.$router.push({
          path: '/team_building_details',
          query: {
            item: JSON.stringify(e)
          }
        })
      } else {
        this.gotoNews(e)
      }
    },
    gotoNewList(index) {
      console.log(index)
      // if (index !== 2) {
      //   this.$store.commit('setOpenNav', [6, index + 1])
      //   this.$router.push({
      //     path: '/company_news'
      //   })
      // } else {
      //   this.gotoNewes('', 2)
      // }
    },
    gotoNewListss(e) {
      console.log(e)
      if (e == 1) {
        this.$store.commit('PATH', '/xyxw')
        this.$store.commit('ID', '7121953223300091904')
        this.$router.push({ path: '/xyxw', query: { id: '7121953223300091904' } })
      }
      if (e == 2) {
        this.$store.commit('PATH', '/dthd')
        this.$store.commit('ID', '7119321341903245312')
        this.$router.push({ path: '/dthd', query: { id: '7119321341903245312' } })
      }
      if (e == 3) {
        this.$store.commit('PATH', '/jxdt')
        this.$store.commit('ID', '7121945916050771968')
        this.$router.push({ path: '/jxdt', query: { id: '7121945916050771968' } })
      }
      if (e == 4) {
        this.$store.commit('PATH', '/tzgg')
        this.$store.commit('ID', '7121953621465370624')
        this.$router.push({ path: '/tzgg', query: { id: '7121953621465370624' } })
      }
      // this.$store.commit('setOpenNav', [6, e])
      // if (e === 0) {
      //   this.$router.push({
      //     path: '/industry_news'
      //   })
      // } else if (e == 1) {
      //   this.$router.push({
      //     path: '/company_news'
      //   })
      // } else if (e == 2) {
      //   this.$router.push({
      //     path: '/party_building'
      //   })
      // } else if (e == 3) {
      //   this.$router.push({
      //     path: '/video_news'
      //   })
      // } else if (e == 4) {
      //   this.$router.push({
      //     path: '/shape'
      //   })
      // }
    },
    getNewsVideo() {
      // this.loadingVideo = true
      axios.post('/home/newNews', { pageNum: 1, pageSize: 1, type: 4 }).then(res => {
        const arr = res.data.data.newsList
        if (arr.length > 0) {
          arr.map(item => {
            this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url)
          })
          this.newsUrl = arr[0].imgUrl
        }

        // this.loadingVideo = false
      })
    },
    getNewsG() {
      axios.post('/home/newNews', { pageNum: 1, pageSize: 6, type: 1 }).then(res => {
        this.newsListG = res.data.data.newsList
        this.newsListG.map(item => {
          this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url)
        })
      })
    },
    getNewsF() {
      // this.loadingF = true
      axios.post('/home/newNews', { pageNum: 1, pageSize: 6, type: 5 }).then(res => {
        this.newsListF = res.data.data.newsList
        this.loadingF = false
      })
    },
    gotoOtherPage(e) {
      this.$store.commit('PATH', '/xyxw')
      this.$store.commit('ID', '7121953223300091904')
      this.$router.push({ path: '/xyxw', query: { id: '7121953223300091904', ids: e.id } })
      console.log(e)
    },
    gotoOtherPage2(e) {
      // this.$router.push('/tzgg1')
      this.$store.commit('PATH', '/tzgg')
      this.$store.commit('ID', '7121953621465370624')
      this.$router.push({ path: '/tzgg', query: { id: '7121953621465370624', ids: e.id } })
      console.log(e)
    },
    gotoOtherPage3(e) {
      this.$store.commit('PATH', '/dthd')
      this.$store.commit('ID', '7119321341903245312')
      this.$router.push({ path: '/dthd', query: { id: '7119321341903245312', ids: e.id } })
      console.log(e)
    },
    gotoOtherPage4(e) {
      this.$store.commit('PATH', '/jxdt')
      this.$store.commit('ID', '7121945916050771968')
      this.$router.push({ path: '/jxdt', query: { id: '7121945916050771968', ids: e.id } })
      console.log(e)
    },
    changeNavs(index) {
      this.newsList = []
      this.isActive = index
      if (index === 0) {
        this.getNews()
      } else if (index === 1) {
        this.getNewsA()
      } else if (index === 2) {
        this.getNewsB()
      }
    },
    getHtmlBodyHeight() {
      //获取滚动时的高度
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      console.log(this.scrollTop)
      if (this.scrollTop > 600) {
        this.showMenu = true
      } else {
        this.showMenu = false
      }
      // if (this.scrollTop > 0) {
      //   this.goTop()
      //   this.scrollTop = 0
      // }
    },
    goTop() {
      var timer = null
      cancelAnimationFrame(timer)
      timer = requestAnimationFrame(function fn() {
        var oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 50
          timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(timer)
        }
      })
    },
    goNet(e) {
      if (e == 1) {
        window.open('https://www.chsi.com.cn/')
      }
      if (e == 2) {
        window.open('http://crgk.jleea.com.cn/')
      }
      if (e == 3) {
        window.open('https://spnz.yxbyun.com/#/login')
      }
    }
  }
}
</script>

<style>
.banner_box_index {
  width: 100%;
  position: relative;
}
.banner_text {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner_text_phone span {
  font-size: 12px !important;
  line-height: 63px;
}
.banner_text_phone p {
  font-size: 12px !important;
}
.card {
  max-width: 1200px;
  margin: 0px auto;
}
.news_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding-left: 30px;
  background: linear-gradient(90deg, rgba(88, 179, 227, 0.4), rgba(169, 213, 79, 0.4));
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.lunbo {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  cursor: pointer;
}
.news_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.news_nav .news_nav_left {
  display: flex;
}
.news_nav_right {
  color: #999999;
}
.news_nav_right:hover {
  color: rgb(14, 73, 141);
  cursor: pointer;
}
.news_nav .news_nav_left .news_nav_item {
  margin: 0 20px;
  line-height: 30px;
  border-bottom: 2px solid white;
  cursor: pointer;
  padding: 0 10px;
}
.news_nav .news_nav_left .news_nav_item.active {
  color: rgb(14, 73, 141);
  font-weight: 500;
  border-bottom: 2px solid rgb(14, 73, 141);
}
.nav_body {
  margin-top: 10px;
  min-height: 212px;
}
.nav_body .nav_item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.nav_body .nav_item:hover {
  color: rgb(14, 73, 141);
  cursor: pointer;
}
.nav_item_left {
  display: flex;
  width: calc(100% - 110px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nav_item_left .icon i {
  color: #999999;
  font-size: 18px;
  margin-right: 10px;
}
.nav_item_left .news_title {
  overflow: hidden;
  height: 20px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: normal;
}
.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card_header .card_title {
  color: rgb(14, 73, 141);
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  padding: 0 5px;
  border-bottom: 2px solid rgb(14, 73, 141);
}
.card_header .card_more {
  color: #999999;
  cursor: pointer;
}

.card_header .card_more:hover {
  color: rgb(14, 73, 141);
  cursor: pointer;
}
.blue_box {
  background: rgb(14, 73, 141);
  padding: 20px 0;
}
.blue_box .blue_1200 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  min-height: 220px;
  overflow: hidden;
}
.project_boxs .proe_access {
  width: 30px;
  height: 220px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  position: absolute;
  left: 0;
  line-height: 220px;
  text-align: center;
  top: 0;
  z-index: 99;
}
.project_boxs .next_access {
  width: 30px;
  height: 220px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  position: absolute;
  right: 0;
  line-height: 220px;
  text-align: center;
  top: 0;
  z-index: 99;
}
.project_boxs {
  position: relative;
  margin-top: 20px;
  overflow-y: hidden;
  height: 220px;
}
.blue_1200 .card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blue_1200 .card_header .card_title {
  color: #fff;
}
.blue_1200 .card_header .card_more {
  color: #f1f1f1 !important;
}
.project_boxs .item_boxes {
  background: #fff;
  padding: 10px;
  position: relative;
}
.project_boxs .item_boxes img {
  height: 200px;
  width: 100%;
}
.project_boxs .item_boxes .item_footer {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 40px;
  background: rgba(0, 0, 0, 0.2);
  line-height: 40px;
  color: #fff;
  padding-left: 20px;
}
/* 后加的 */
.banner_down {
  width: 100%;
  height: 40px;
  background: linear-gradient(90deg, #58b3e3, #a9d54f);
}
.sy_tubiao {
  height: 270px;
  width: 100%;
  background: #fff;
}
.tubiao_center {
  height: 250px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  /* cursor: pointer; */
}
.tubiao_text {
  text-align: center;
}
.tubiao_i {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  transition: 2s ease-out all;
  background: linear-gradient(90deg, #58b3e3, #a9d54f);
}
.tubiao_i :hover {
  width: 120px;
  height: 120px;
  cursor: pointer;
  transform: rotate(360deg);
  background: linear-gradient(90deg, #a9d54f, #58b3e3);
  border-radius: 5px;
}
.tubiao_text p {
  margin-top: 10px;
  color: rgb(14, 73, 141);
  font-size: 14px;
}
.tubiao_text i {
  color: #fff;
  font-size: 60px;
  line-height: 120px;
}
.banner_xinwen {
  width: 100%;
  background: #fff;
  overflow: hidden;
  position: relative;
}
.banner_xinwen h3 {
  text-align: center;
  font-size: 30px;
  font-weight: normal;
}
.xinwen_tabs {
  width: 1200px;
  margin: auto;
  margin-top: 60px;
}
.xinwen_tabs ::v-deep .el-tabs__nav-wrap::after {
  background-color: #fff;
}
.xinwen_tabs ::v-deep .el-tabs__active-bar {
  background-color: #fff;
}
.xinwen_tabs ::v-deep .el-tabs__item.is-active {
  color: #000;
}
.xinwen_tabs ::v-deep .el-tabs__item {
  color: rgb(153, 153, 153);
  font-size: 25px;
}
.xinwen_icon {
  width: 5px;
  height: 30px;
  background: linear-gradient(90deg, #a9d54f, #58b3e3);
  position: absolute;
  top: 135px;
  left: 343px;
}
.xinwen_tabs ::v-deep #tab-a {
  border-right: 1px solid rgb(153, 153, 153);
}
.bg_menu {
  width: 160px;
  height: 612px;
  position: absolute;
  top: 22px;
  left: calc((100% - 1200px) / 2 - 190px);
  z-index: 999;
  border: 5px solid #5084bf;
  border-radius: 5px;
}
.bg_zi {
  position: absolute;
  top: 130px;
  right: calc(((100% - 1200px) / 2 / 2) - 97px);
  z-index: 999;
}
.bg_menu .el-menu--collapse .el-submenu .el-submenu__title {
  /* background: red !important; */
  text-align: right;
}
.bg_menu .el-menu--collapse .el-submenu .el-submenu__title span {
  visibility: initial;
  display: inline;
  /* color: rgb(14, 73, 141); */
}
.bg_menu .el-menu--collapse {
  width: 160px;
  height: 560px;
}
.bg_menu .el-menu--collapse .el-menu-item span {
  visibility: initial;
  display: inline;
  /* color: rgb(14, 73, 141); */
}
.bg-menu ::v-deep .el-menu {
  border-right: 0 !important;
  /* background: #f6fbff; */
  background: red;
}
.menu_title {
  width: 160px;
  height: 50px;
  font-size: 19px;
  background: #5084bf;
  text-align: center;
  line-height: 50px;
  /* border-right: solid 1px #5084bf; */
  color: #fff;
}
.card_body_div {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 20px 10px 20px;
  text-align: center;
  cursor: pointer;
}
.card_body_img {
  height: 45px;
  object-fit: contain;
  margin-top: 18px;
}
.card_body_p {
  font-size: 15px;
  margin-top: 20px;
}
.nav_item_right {
  font-size: 15px;
  color: #999;
}
/* 底部轮播图 */
.warp {
  width: 50px * 4;
  height: 210px;
  margin: 0 auto;
  overflow: hidden;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}
.ul-item {
  display: flex;
}
.li-item {
  width: 367px;
  height: 210px;
  margin-right: 10px;
  line-height: 200px;
  /* background-color: #999; */
  color: #fff;
  text-align: center;
  font-size: 30px;
}
/* 手机端五个小图标 */
.yewuPhone .yewu_box {
  padding: 0;
}
.yewuPhone .yewu_box .yewu_box_item_box {
  padding: 0;
}
.yewuPhone .yewu_box .yewu_box_item_box .yewu_box_item {
  width: 100px;
  height: 100px;
}
.yewuPhone .yewu_box .yewu_box_item_box .yewu_box_item .yewu {
  width: 100px;
}
.yewuPhone .yewu_box .yewu_box_item_box .yewu_box_item .yewu .card_a .card_img {
  width: 40px;
}
.yewuPhone .yewu_box .yewu_box_item_box .yewu_box_item .yewu .card_a .yewu_title {
  font-size: 15px;
  margin-top: 0;
}
.yewuPhone .yewu_box .yewu_box_item_box .yewu_box_item .yewu .yewu_content .yewu_btn {
  padding: 2px 4px;
  font-size: 13px;
}
.yewuPhone .yewu_box .yewu_box_item_box {
  width: 600px;
}
.phoneNavR {
  z-index: 999;
  position: fixed;
  right: -15px;
  top: 50%;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  line-height: 32px;
  box-shadow: 0 0 10px rgb;
}
.phoneNavR i {
  font-size: 18px;
  color: #666;
  margin-left: 1px;
  margin-top: 7px;
}
</style>